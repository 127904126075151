import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Dabudu.com' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      შექმენი შენი მომავალი:
      </Text>
      <Text variant='p'>
      - გლობალურ ბაზარზე
      </Text>
      <Text variant='p'>
      - დისტანციურად
      </Text>
    </Card>
  </Section>
)

export default Commitment
